import React from "react";
import {container} from './video.module.css'

function Video() {
  return (
    <div className={container}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/ka36acqyrSw?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Video;
